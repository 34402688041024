// vendor styles
import '../node_modules/uikit/dist/css/uikit.css'

// vendor js

// set uikit in global variable
import UIkit from 'uikit'
window.UIkit = UIkit;

// icons
import Icons from '../node_modules/uikit/dist/js/uikit-icons';
UIkit.use(Icons);
